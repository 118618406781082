@import url(https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css);


* {
  box-sizing: border-box;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  background: #f5f6f9;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}

.App {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 400;
  font-size: 14px;
}

.App.login {
  max-width: 400px;
  padding: 40px;
}

.App.login input {
  width: 100%;
  display: block;
}

.App,
.btn.bg-sent,
.btn.bg-signed,
.btn.bg-late {
  color: #4C4C4C;
}

.App.loading {
  text-align: center;
  padding-top: 40px;
}

.btn.btn-sent,
.btn.btn-signed,
.btn.btn-late {
  color: #fff;
}

.header {
  background: #333;
  height: 55px;
  line-height: 55px;
}

.header,
.header a {
  color: #fff;
}

.header a {
  font-weight: 700;
}

.header a:hover {
  text-decoration: none;
}

.header ul li {
  position: relative;
}

.header ul li ul {
  top: 100%;
  left: 0;
  background-color: #565656;
  position: absolute;
  z-index: 99;
  padding: 0;
  margin: 0;
  padding: 10px 15px;
  line-height: 14px;
  list-style: none;
  width: 160px;
  display: none;
}

.header ul li:hover ul {
  display: block;
}

.header ul li ul a {
  line-height: 30px;
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, .2);
  font-size: 13px;
}

.header ul li ul li:last-child a {
  border-bottom: 0;
}

.top {
  padding-top: 40px;
  padding-bottom: 40px;
}

.clients .clientHandle {
  cursor: pointer;
}

.client {
  line-height: 14px;
  background: #fff;
  border: 1px solid #f0f0f7;
}

/*.client h4 {
  font-weight: 500;
  font-size: 16px;
}*/

.btn {
  font-weight: 500;
  font-size: 14px;
}

.btn-success {
  background-color: #47b972;
  border-color: #47b972;
}

.bg-sent {
  background-color: #E6EFFE;
}

.bg-signed {
  background-color: #28a745;
}

.bg-late {
  background-color: #FBE5ED;
}

.bg-new {
  background-color: #f8f9fa;
}

.btn-sent {
  background-color: #0364FB;
}

.btn-new {
  background-color: #dddddd;
}

.btn-signed {
  background-color: #4cd964;
}

.color-late {
  color: #ED4959;
}

.color-sent {
  color: #0364FB;
}

.color-new {
  color: #dddddd;
}

.color-signed {
  color: #28a745;
}

.color-late {
  color: #ED4959;
}

.btn-primary {
  background: #0095ff;
  border-color: #0095ff;
}


.btn-primary:hover {
  background: #1ba0ff;
  border-color: #1ba0ff;
}

.history {
  padding: 0;
  margin: 0;
  position: relative;
  list-style: none;
}

.history li:last-child {
  margin-bottom: 0;
}

.history:before {
  content: '';
  position: absolute;
  width: 1px;
  background: #dee2e6;
  top: 2px;
  left: 8px;
  bottom: 2px;
}

.history li {
  font-size: 13px;
  position: relative;
  line-height: 14px;
  margin-bottom: 15px;
  padding-left: 30px;
}

.history li:before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  background: #dee2e6;
  border: 3px solid #fff;
  border-radius: 50%;
  left: 0;
  top: 2px;
}

.history li[data-status="success"]:before {
  background: #4cd964;
}

.history li[data-status="sent"]:before {
  background: #0069d9;
}

.btn-status {
  width: 130px;
}

.avatar {
  position: relative;
  display: block;
  z-index: 1;
}

.avatar img {
  position: relative;
  z-index: 1;
}

.avatar em {
  text-transform: uppercase;
  background-color: #ddd;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  height: 45px;
  line-height: 45px;
  width: 45px;
}

.avatar.avatar-lg em {
  font-size: 28px;
  line-height: 90px;
  height: 90px;
  width: 90px;
}

h4 svg,
.btn svg {
  position: relative;
  top: -1px;
}

.close:active,
.close:focus {
  outline: none;
}

.contract {

}

.description {
  width: 35%;
}

.modal-backdrop {
  background: #000;
}

.modal-backdrop.show {
  opacity: .25;
}

.contractsWrap {
  overflow-y: scroll;
  width: 100%;
}

.contracts {
  
}

.contracts .contract {
  position: relative;
  float: left;
  cursor: pointer;
  /*width: 170px;*/
}

.contracts .contract:hover .contractInner {
  color: #fff;
  background: #333;
}

.contracts .contract .contractInner {
  margin-right: 10px;
  background: #f5f6f9;
  height: 40px;
}

.contracts .contract .contractInner:hover {
  background-color: #0069d9!important;
  border-color: #0062cc!important;
}

.search .input-group-append button {
  border: 1px solid #ced4da;
}

.form-control {
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .form-control {
    font-size: 16px;
  }
  .description {
    width: auto;
  }
  .top {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .btn-status {
    width: 12px;
    height: 12px;
    padding: 0;
    border-radius: 50%;
    font-size: 12px;
  }
  .modal-backdrop.show {
    display: none!important;
  }
  .modal {
    position: static;
  }
  .modal-content.shadow-lg {
    box-shadow: none!important;
  }
  .modal-dialog {
    border-radius: .3rem;
    border: 1px solid #f0f0f7;
    margin: 0;
    margin-bottom: .5rem;
    max-width: inherit;
  }
  /*.modal-header {
    background: #333!important;
    color: #fff!important;
  }
  .modal-header .close {
    color: #fff;
    text-shadow: none;
  }*/
  .modal-open {
    overflow: inherit;
  }
  .modal-dialog-centered::before {
    display: none;
  }
  .modal-dialog-centered {
    min-height: 0;
  }
  .clients [data-selected="true"] > .clientHandle {
    display: none;
  }
}

